import React from "react";
import { Link } from "gatsby";
import { Jumbotron } from "react-bootstrap";

import SEO from "components/../containers/SEO";
import Image from "components/Image";

import "../style/main.scss";

const NotFoundPage = () => {
  return (<>
    <SEO
      metadata={{
        titleTemplate: "Page Not Found",
        description: "",
        keywords: "",
      }}
    />
    <Jumbotron
      className='bg-white h-100 mb-0 d-flex justify-content-center align-items-center text-center text-body'
      fluid
    >
      <div>
        <Link className='d-inline-flex mb-4' to='/'>
          <Image
            className="img-fluid d-inline-block mx-auto"
            style={{ width: "130px" }}
            fileName='logo.png'
          />
        </Link>
        <h1 className="display-4">Page Not Found</h1>
        <p className='lead mb-5'>
          The page is out of date, has been deleted or did not exist at all
        </p>
        <Link className="btn btn-dark" to='/'>Go back to the main page</Link>
      </div>
    </Jumbotron>
  </>);
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
